/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

@media screen and (max-width: 450px) {
  .#{$namespace} {
    & .c-image-slider {
      height: 140px;
    }
  }
}
