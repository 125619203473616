/******************************************
  Some tips for creating layouts :
  - Add `l` prefix before the name of layouts
  - Or Just copy paste this structure of styling code into your new layouts styling file
  - Dont forget to register your layouts into `_layouts.scss`
******************************************/
/** This Place For Layouts Home **/
.#{$namespace} {
  & .l-error-pages {
    .t-error-content {
      max-width: 560px;
    }

    .error-traceid {
      left: 0;
      bottom: 0;
    }

    .t-error {
      min-height: 100vh;
    }
  }
}

/* ************************************ */
