/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

@media screen and (max-width: 768px) {
  /******************************************
    Create an awesome styling here
  ******************************************/
  .#{$namespace} {
    & .activity-detail-headline-price {
      position: fixed !important;
      bottom: 0;
      left: 0;
      z-index: 9;
      margin-bottom: 0;
      border: 0;
      background: white;
      border-top: 1px solid #DDD;
      border-radius: 0;
    }
  }
}
