/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

@media (min-width: 768px) {
  .#{$namespace} {
    & .c-home-shortcut-panel {
      margin-top: 0;
      position: absolute;
      top: -50px;
    }
  }
}

@media screen and (max-width: 768px) {
  .#{$namespace} {
    & .c-home-shortcut-panel {
      & .home-shortcut-panel {
        &__container {
          & .link-col-wrapper {
            &:not(:last-child)::after {
              width: 0;
            }
          }
        }
      }
    }
  }
}

/******************************************
  Max Phone Screen
******************************************/
@media screen and (max-width: $screen-xs-max) {
  .#{$namespace} {
    & .c-home-shortcut-panel {
      & .home-shortcut-panel {
        &__container {
          & .link-col-wrapper {
            height: 120px;
            overflow: visible;

            & .link-wrapper {
              & .title {
                font-size: 12px;
                word-break: break-word;
              }

              & .link-icon-wrapper {
                width: 40px;
                height: 40px;

                & .icon-wrapper {
                  & .t-icon {
                    font-size: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
