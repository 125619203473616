/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

@media screen and (max-width: 768px) {
  /******************************************
    Create an awesome styling here
  ******************************************/
  .#{$namespace} {
    & .c-home-activities {
      .home-activities__container {
        .carousel-active {
          .carousel-item {
            width: 25% !important;

            &.first-item {
              padding-left: 24px !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  /******************************************
    Create an awesome styling here
  ******************************************/
  .#{$namespace} {
    & .c-home-activities {
      .home-activities__container {
        .carousel-active {
          .carousel-item {
            width: 50% !important;
          }
        }
      }
    }
  }
}
