/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

/******************************************
  Max Phone Screen
******************************************/
@media screen and (max-width: $screen-xs-max) {
  .#{$namespace} {
    & .c-home-hero-main {
      min-height: unset !important;
      max-height: unset !important;
      height: auto !important;

      & .#{$prefix} {
        &-hero-main {
          &-content {
            min-height: unset !important;
            max-height: unset !important;
            height: auto !important;
          }
        }
      }

      & .home-hero-main {
        &__cta-btn {
          margin-top: 24px;
        }
      }
    }
  }
}
