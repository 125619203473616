/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Qualmark **/
.#{$namespace} {
  & .c-qualmark-rating {
    width: 100%;
    position: relative;

    & .qualmark-rating {
      &__inner-container {
        position: relative;
        width: 100%;
        height: auto;
      }

      &__icon {
        width: 60px;
        max-width: 60px;
        height: 65px;
        display: inline-block;

        & .icon {
          &-image {
            width: 100%;
          }
        }
      }

      &__content-text {
        padding-left: 16px;
        width: 80%;
        display: inline-block;

        & .title {
          margin-bottom: 8px;
        }

        & .rating {
          color: #DC8E00;
        }
      }
    }
  }
}

/* ************************************ */
