/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

@media screen and (max-width: $screen-md-max) {
  /******************************************
    Create an awesome styling here
  ******************************************/
  .#{$namespace} {
    & .c-available-deals {
      & .deal {

        //Two col
        &-2 {

          //Item wrapper
          & .deal-item {
            width: 50%;
            display: table-cell;

            &:first-child {
              padding-right: 8px;
            }

            &:last-child {
              padding-right: 8px;
            }
          }

          //Sub block
          & .item {

            //Left side
            &-left {
              width: 100%;
              height: 231px;
              display: block;
            }

            //Right side
            &-right {
              width: 100%;
              font-size: 14px;
              display: block;
              text-align: left;
              min-height: 192px;
            }

            //Attributes
            &-deal-name {
              color: $color-grey-darker;
              font-size: 16px;
              font-weight: bold;
              height: 35px;
              overflow: hidden;
              margin-bottom: 10px;
            }

            &-place-name {
              padding: 5px 0;
            }

            &-label-card {
              font-size: 14px;
              font-weight: bold;
              padding: 7px 25px;
            }

            &-attr {
              width: 50%;
              float: left;

              &-fs-bigger {
                font-size: 24px;
              }

              &-price {
                width: 50%;
                float: left;
                padding-left: 10px;
                text-align: right;
                margin-top: -12px;
                min-height: 68px;
              }
            }

            &-link-view {
              display: none;
            }
          }
        }

        &-4 {

          //Item wrapper
          & .deal-item {
            width: 50%;
            display: block;
            float: left;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  /******************************************
    Create an awesome styling here
  ******************************************/
  .#{$namespace} {
    & .c-available-deals {
      & .deal {

        //One col
        &-1 {

          //Item wrapper
          & .deal-item {
            width: 100%;
            display: block;
          }

          //Sub block
          & .item {

            //Left side
            &-left {
              width: 100%;
              height: 231px;
              display: block;
            }

            //Right side
            &-right {
              width: 100%;
              font-size: 14px;
              display: block;
              text-align: left;
              min-height: 192px;
            }

            //Attributes
            &-deal-name {
              color: $color-grey-darker;
              font-size: 16px;
              font-weight: bold;
              height: 35px;
              overflow: hidden;
              margin-bottom: 10px;
            }

            &-place-name {
              padding: 5px 0;
            }

            &-label-card {
              font-size: 14px;
              font-weight: bold;
              padding: 7px 25px;
            }

            &-attr {
              width: 50%;
              float: left;

              &-fs-bigger {
                font-size: 24px;
              }

              &-price {
                width: 50%;
                float: left;
                padding-left: 10px;
                text-align: right;
                margin-top: -12px;
                min-height: 68px;
              }
            }

            &-link-view {
              display: none;
            }
          }
        }

        //Two col
        &-2 {

          //Item wrapper
          & .deal-item {
            width: 100%;
            display: block;

            &:first-child {
              padding-right: 0;
            }

            &:last-child {
              padding-left: 0;
            }
          }
        }

        &-3 {
          width: 100%;

          //Item wrapper
          & .deal-item {
            width: 100%;
            padding-right: 0;
            display: block;

            &:nth-of-type(2) {
              padding: 0;
            }
          }
        }

        &-4 {

          //Item wrapper
          & .deal-item {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  /******************************************
    Create an awesome styling here
  ******************************************/
  .#{$namespace} {
    & .c-available-deals {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
