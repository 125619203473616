@media print {
  .travlr-ui {
    background-color: #FFF;
    color-adjust: exact;
    margin: 0;
    size: A4;

    // hide elements
    #cookieConsent,
    .t-navbar-top-left > .t-navbar-top-left-burger,
    .t-navbar-top-left-menus,
    .t-navbar-with-scroll,
    .t-navbar-top-right,
    .t-notification,
    .t-navbar-sidebar,
    .t-navbar-footer,
    .t-icon,
    .t-btn,
    .t-btn-primary,
    .t-tabs {
      display: none !important;
    }

    // navbar
    .trv-header.t-navbar-top {
      box-shadow: none !important;
      margin: 0 auto;
      position: relative !important;
      width: 100%;

      @include media-up(md) {
        max-width: 714px;
        width: 65%;
      }

      @include media-up(lg) {
        max-width: 778px;
      }

      @include media-up(xl) {
        max-width: 778px;
      }

      .t-navbar-top-main {
        border-bottom: 1px solid #E6E6E6;
        margin: 0 16px 10px;
        padding: 0;
      }

      .t-navbar-top-left {
        height: 56px;
      }

      .t-navbar-top-left-logo {
        vertical-align: top !important;

        img {
          height: 44px;
          width: auto;
        }
      }
    }

    // main content
    .t-main-content {
      margin-top: 15px !important;
    }

    // grayscale images
    img:not(.t-navbar-top-left-logo-image) {
      filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
      filter: gray; /* IE6-9 */
      -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
    }

    // utils
    // overide the UIkit display !important
    #app .t-migration {
      .print-hidden {
        display: none !important;
      }

      .print-show {
        display: block !important;
      }
    }
  }
}
