/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Example **/
.#{$namespace} {
  & .c-shortlist {
    & .c {
      &-card {
        &-see-more {
          &-text {
            font-size: 17px;
            font-weight: 600;
            text-align: right;
          }

          &-icon {
            font-size: 15px;
            margin-left: 5px;
            top: 0;
            position: relative;
          }
        }

        &-list-owner {
          &-caption {
            font-size: 32px;
            font-weight: bold;
            color: $color-grey-darkest;
          }

          &-item {
            font-weight: 600;

            &-share-list {
              padding-right: 15px;

              & a {
                border: 1px solid $color-secondary-dark;
                font-size: 18px;
                padding: 12px 20px;
              }
            }

            &-follow-list {
              & a {
                font-size: 18px;
                padding: 12px 20px;
              }
            }
          }

          &-item:first-child {
            margin-right: 20px;
          }

          &-share-list {
            border: 1px solid $color-primary-dark;

            @include border-radius(4px);
          }

          &-avatar {
            &-img {
              width: 32px;
              height: 32px;
              margin-right: 20px;
              display: block;
              background-position: center;
              background-repeat: no-repeat;
              overflow: hidden;
              position: relative;

              @include border-radius(50%);
              @include background-size(cover);
            }
          }
        }

        /* Grouped Shortlist */
        &-grouped-item {
          margin-bottom: 25px;

          &-img {
            height: 180px !important;
            display: block;
            background-position: center;
            background-repeat: no-repeat;
            overflow: hidden;
            position: relative;

            @include background-size(cover);
          }
        }

        /* Place/Listing Shortlist */
        &-list-item {
          margin-right: 20px;
          margin-bottom: 20px;

          &-img {
            width: 100%;
            height: 174px;
            background-position: center center;

            @include background-size(cover);
            @include border-radius(4px 4px 0 0);
          }
        }

        & .c-shortlist-rating {
          width: 100%;

          & .star-rating {
            &-text {
              padding-right: 5px;
              color: $color-front-star;
            }
          }

          & .icon-star-rating-filled {
            font-size: 16px;
            display: table-cell;
          }

          & .back-stars {
            color: $color-back-star;
            position: relative;
          }

          & .front-stars {
            color: $color-front-star;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}

/* ************************************ */
