/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Humm Instruction Panel **/
.#{$namespace} {
  & .c-humm-instruction-panel {
    background-color: #FF7412;
    padding: 64px 0 128px;
    position: relative;
    z-index: 3;

    & .floating-panel-wrapper {
      position: relative;

      & .floating-panel-container {
        margin: 0 auto;
        border-radius: 8px;
        position: absolute;
        top: 64px;
        width: 100%;

        @include box-shadow(0 4px 24px rgba(0, 0, 0, 0.08));

        & .steps-container {
          display: table;
          padding: 0 16px;

          & .step-col {
            display: table-cell;
            width: 33.333333%;
            height: 300px;
            vertical-align: middle;
            font-weight: bold;

            & .step-img-container {
              height: 140px;
              width: 100%;
              display: table;

              & .inner-wrapper {
                height: 150px;
                width: 100%;
                display: table-cell;
                vertical-align: middle;

                & .step-img-1 {
                  height: 90px;
                }

                & .step-img-2 {
                  height: 110px;
                }

                & .step-img-3 {
                  height: 115px;
                }
              }
            }

            & .description-container {
              height: 100px;
              padding: 16px 44px 0;
            }
          }
        }
      }
    }
  }
}

/* ************************************ */
