/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

/******************************************
  Max Phone Screen
******************************************/
@media screen and (max-width: $screen-xs-max) {
  .#{$namespace} {
    & .c-home-find-inspiration {
      & .find-inspiration {
        & .last-item-mobile {
          margin-top: 16px;
        }
      }

      & .c-card {
        & .t-card-title {
          min-height: 180px;
          max-height: 180px;

          & .t-card-title-content {
            &.bottom {
              top: 73%;
            }

            & .title {
              font-size: 20px;
            }

            & .description {
              font-size: 14px;
            }

            &.fi-card {
              @media screen and (max-width: 768px) {
                padding: 0 16px;
              }

              &.v-align-bottom {
                @media screen and (max-width: 768px) {
                  bottom: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .#{$namespace} {
    & .c-home-find-inspiration {
      & .c-card {
        & .t-card-title {
          & .t-card-title-content {
            &.bottom {
              top: 73%;
            }
          }
        }
      }
    }
  }
}
