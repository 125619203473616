/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

@media screen and (max-width: 768px) {
  .#{$namespace} {
    & .c-home-trending-destinations {
      & .c-section-title-button {
        padding: 0 16px;
      }

      & .trending-destinations {
        &__container {
          overflow-x: hidden;
          padding: 0 !important;
        }
      }

      & .c-card {
        & .#{$prefix} {
          &-card-title {
            min-height: 250px;
            max-height: 250px;
          }
        }
      }

      & .carousel-item {
        &.first-item {
          padding-left: 24px !important;
        }
      }

      & .flickity {
        &-slider {
          left: 16px !important;
        }
      }
    }
  }
}

/******************************************
  Max Phone Screen
******************************************/
@media screen and (max-width: $screen-xs-max) {
  .#{$namespace} {
    & .c-home-trending-destinations {
      & .c-card {
        & .#{$prefix} {
          &-card-title {
            &-content {
              &.bottom {
                padding-bottom: 12px;
              }
            }
          }
        }

        & .title {
          font-size: 14px;
          line-height: 24px;
          word-break: break-word;
          hyphens: auto;
        }

        & .description {
          font-size: 12px;
          margin: 0;
          word-break: break-word;
          hyphens: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .#{$namespace} {
    & .c-home-trending-destinations {
      & .c-card {
        & .#{$prefix} {
          &-card-title {
            min-height: 160px;
            max-height: 160px;
          }
        }
      }
    }
  }
}
