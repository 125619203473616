/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

/******************************************
  Max Phone Screen
******************************************/
@media screen and (max-width: $screen-xs-max) {
  /******************************************
    Create an awesome styling here
  ******************************************/
  .#{$namespace} {
    & .c-humm-faq {
      & .humm-faq {
        &__container {
          padding-left: 0;
          padding-right: 0;
          padding-top: 32px !important;
          padding-bottom: 32px !important;

          & .t-heading-1 {
            font-size: 20px;
          }

          & .t-heading-2 {
            font-size: 16px;
            margin-left: 16px;
          }

          & .t-accordion-item {
            border-radius: 0;
            margin-top: 16px;

            & .t-accordion-item-list {
              &.active {
                max-height: none !important;
              }
            }

            &.two-lines {
              max-height: 72px;

              &.active {
                max-height: none !important;
              }
            }

            &.three-lines {
              max-height: 92px;

              &.active {
                max-height: none !important;
              }
            }

            & .list-bullet-solid {
              list-style: disc;
              margin-left: 16px;
            }
          }

          & .t-accordion-item-body {
            font-size: 14px;
          }

          & .active {
            & .color-humm {
              color: $color-humm;
            }

            & .t-accordion-item-body {
              border-top: 1px #D8D8D8 solid;
            }
          }
        }
      }
    }
  }
}
