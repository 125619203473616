/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

/******************************************
  Max Medium Screen
******************************************/
@media screen and (max-width: $screen-sm-max) {
  .#{$namespace} {
    & .l-home {
      & .home {
        &-wrapper-main {
          & .sections-order {
            &__item {
              margin-top: 24px !important;
            }
          }
        }

        &-wrapper-shortcut-panel {
          min-height: 80px;
        }
      }
    }
  }
}
