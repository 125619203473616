/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Find Inspiration **/
.#{$namespace} {
  & .c-humm-plan-table {
    background-color: #F3F4F6;
    position: relative;
    padding: 48px 0;
    padding-top: 300px;
    padding-bottom: 0;

    & .humm-plan-table {
      &__container {
        & .big-buy-btn {
          display: none;
        }

        & .buy-btn {
          display: none;
          font-size: 14px;
          border: 1px #FE6B00 solid;
          border-radius: 4px;
          padding: 8px 0;
          width: 240px;
          cursor: pointer;

          & .btn-icon {
            width: 26px;
          }

          & .btn-text {
            display: inline-block;

            @include transform(translateY(-2px));
          }
        }

        & .table-container {
          font-size: 16px;

          & .table-inner-container {
            display: table;

            & .v-m {
              vertical-align: bottom;
            }

            & .table-cell {
              display: table-cell;

              & .table-cell-content {
                height: 56px;
                border-bottom: 1px #D1D5DB solid;
                position: relative;

                &.top-row {
                  height: 120px !important;
                }

                & .table-cell-inner-content {
                  position: absolute;
                  top: 50%;

                  @include transform(translateY(-50%));
                  width: 100%;
                }
              }

              & .buy-logo {
                width: 70px;
              }

              & .fs-28 {
                font-size: 28px;
              }
            }
          }
        }

        & .d-t {
          display: table;
        }

        & .d-t-c {
          display: table-cell;
        }

        & .relative {
          position: relative;
        }
      }
    }

    & .t-w-25 {
      width: 25%;
    }

    & .fw-bold {
      font-weight: bold;
    }
  }
}

/* ************************************ */
