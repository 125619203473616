$backgroundColor: #023850;
$primaryColor: #008080;
$whiteColor: #FFF;
$greyColor: #CECECE;
$textPrimaryColor: $whiteColor;
$maxWidthContainer: 680px;

.travlr-ui {
  // container
  #CybotCookiebotDialog {
    background-color: $backgroundColor;
    color: $textPrimaryColor;
    font-family: inherit;
    padding-bottom: 24px;
  }

  #CybotCookiebotDialogBody,
  #CybotCookiebotDialogBody a,
  #CybotCookiebotDialogBody div,
  #CybotCookiebotDialogBodyContentTitle {
    font-family: inherit;
  }

  #CybotCookiebotDialogBody {
    max-width: $maxWidthContainer;
    padding-left: 12px;
    padding-right: 12px;

    @media screen and (min-width: 660px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  #CybotCookiebotDialogBodyContent {
    padding-top: 24px;
    padding-bottom: 16px;
    padding-left: 0 !important;

    @media screen and (min-width: 660px) {
      margin-left: 0;
    }
  }

  // title
  #CybotCookiebotDialogBodyContentTitle {
    color: $textPrimaryColor;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  // description text
  #CybotCookiebotDialogBodyContentText {
    font-size: 14px;
    font-weight: 400;
  }

  // cta button
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
    display: flex !important;
    flex-direction: column;
    margin-bottom: 19px;
    width: 100%;

    a {
      border-radius: 100px;
      border: 1px solid transparent;
      font-size: 14px;
      font-weight: 700;
      flex: 1;
      margin-left: 0;
      padding-top: 6px;
      padding-bottom: 6px;
      width: auto !important;
    }

    #CybotCookiebotDialogBodyLevelButtonAccept,
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
      background-color: $primaryColor;
      color: $textPrimaryColor;
    }

    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
    #CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll {
      background-color: transparent;
      border: 1px solid $whiteColor;
    }

    @media screen and (min-width: 660px) {
      flex-direction: row-reverse;

      a {
        margin-left: 12px;

        &:last-child {
          margin-left: 0;
        }
      }
    }
  }

  // checkbox container
  #CybotCookiebotDialogBodyLevelButtons {
    font-size: 14px;
    margin-left: 0 !important;
    width: 100%;

    @media screen and (min-width: 660px) {
      margin-left: 0 !important;
    }
  }

  #CybotCookiebotDialogBodyLevelButtonsRow {
    border-radius: 4px;
    border: 1px solid $greyColor;
    display: flex;
  }

  #CybotCookiebotDialogBodyLevelButtonsSelectPane {
    border-color: transparent;
    flex: 1;
    padding: 8px 8px 0 8px;
  }

  .CybotCookiebotDialogBodyLevelButtonWrapper {
    margin-bottom: 8px;
    margin-right: 20px;
  }

  // checkbox
  input[type="checkbox"].CybotCookiebotDialogBodyLevelButton + label {
    background: none !important;
    font-weight: 400;
    padding-left: 24px !important;

    &::before {
      content: '' !important;
      background-color: $whiteColor;
      border-radius: 2px;
      border: 2px solid $greyColor;
      position: absolute;
      top: 0;
      left: 0;
      height: 14px;
      width: 14px;
    }

    &::after {
      content: '' !important;
      position: absolute;
      display: none;
      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid $textPrimaryColor;
      border-width: 0 3px 3px 0 !important;
      transform: rotate(45deg);
    }
  }

  input[type="checkbox"].CybotCookiebotDialogBodyLevelButton {
    &.CybotCookiebotDialogBodyLevelButtonDisabled {
      & + label {
        &::before {
          background-color: $greyColor !important;
          border-color: $greyColor !important;
          opacity: 0.5;
        }

        &::after {
          border-color: $whiteColor;
        }
      }

      &:checked ~ label {
        &::before {
          background-color: $greyColor !important;
          border-color: $greyColor !important;
        }

        &::after {
          opacity: 0.5;
        }
      }
    }

    &:checked ~ label {
      &::before {
        background-color: $primaryColor;
        border-color: $primaryColor;
      }

      &::after {
        display: block;
      }
    }
  }

  // detail button
  #CybotCookiebotDialogBodyLevelDetailsWrapper {
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 8px 12px;
  }

  #CybotCookiebotDialogBodyLevelDetailsButton {
    font-size: 14px;
  }

  // detail table
  #CybotCookiebotDialogDetail {
    background-color: $backgroundColor;
  }

  #CybotCookiebotDialogDetailBody {
    margin-left: 12px;
    margin-right: 12px;
    width: auto;
    max-width: none;

    @media screen and (min-width: 660px) {
      margin-left: auto;
      margin-right: auto;
      max-width: $maxWidthContainer;
    }
  }
}
