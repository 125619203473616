/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Example **/
.#{$namespace} {
  & .c-available-deals {
    & .deal {
      //One col
      &-1 {
        & .item {
          display: table;
          width: 100%;

          //Left side
          &-left {
            width: 50%;
            height: 356px;
            display: table-cell;
          }

          //Right side
          &-right {
            width: 50%;
            text-align: center;
            display: table-cell;
            vertical-align: middle;
            font-size: 16.8px;

            @include border-radius(0 4px 4px 0);
          }

          //Attributes
          &-deal-name {
            color: $color-grey-darker;
            font-size: 28px;
            font-weight: bold;
            padding-bottom: 20px;
          }

          &-place-name {
            margin-bottom: 10px;
          }

          &-label-card {
            font-size: 16px;
            font-weight: bold;
            padding: 10px 25px;
          }

          &-attr-fs-bigger {
            font-size: 28px;
          }

          &-link-view {
            &-inner {
              font-size: 18px;
            }
          }
        }
      }

      //Two col
      &-2 {
        width: 100%;
        display: table;

        //Item wrapper
        & .deal-item {
          width: 50%;
          display: table-cell;

          &:first-child {
            padding-right: 10px;
          }

          &:last-child {
            padding-left: 10px;
          }
        }

        //Sub block
        & .item {

          //Left side
          &-left {
            width: 50%;
            height: 264px;
            display: table-cell;
          }

          //Right side
          &-right {
            width: 50%;
            padding: 20px;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            font-size: 15px;

            @include border-radius(0 4px 4px 0);
          }

          //Attributes
          &-deal-name {
            color: $color-grey-darker;
            font-size: 16px;
            font-weight: bold;
            height: 68px;
            overflow: hidden;
          }

          &-label-card {
            font-size: 14px;
            font-weight: bold;
            padding: 7px 25px;
          }

          &-attr-fs-bigger {
            font-size: 24px;
          }

          &-link-view {
            &-inner {
              font-size: 16px;
            }
          }
        }
      }

      //Three col
      &-3 {
        display: table;
        width: 100%;

        //Item wrapper
        & .deal-item {
          width: 33.333%;
          display: table-cell;
          padding-right: 16px;
        }

        //Sub block
        & .item {

          //Left side
          &-left {
            width: 100%;
            height: 231px;
          }

          //Right side
          &-right {
            width: 100%;
            font-size: 14px;
            display: block;
            text-align: left;
            min-height: 172px;
          }

          //Attributes
          &-deal-name {
            color: $color-grey-darker;
            font-size: 16px;
            font-weight: bold;
            height: 35px;
            overflow: hidden;
            margin-bottom: 10px;
          }

          &-place-name {
            padding: 5px 0;
          }

          &-label-card {
            font-size: 14px;
            font-weight: bold;
            padding: 7px 25px;
          }

          &-attr {
            width: 50%;
            float: left;

            &-fs-bigger {
              font-size: 24px;
            }

            &-price {
              width: 50%;
              float: left;
              padding-left: 10px;
              text-align: right;
              margin-top: -12px;
              min-height: 68px;
            }
          }

          &-link-view {
            display: none;
          }
        }
      }

      //Four col
      &-4 {
        width: 100%;

        //Item wrapper
        & .deal-item {
          width: 25%;
          display: table-cell;
          padding-right: 16px;
        }

        //Sub block
        & .item {

          //Left side
          &-left {
            width: 100%;
            height: 200px;
          }

          //Right side
          &-right {
            width: 100%;
            font-size: 14px;
            display: block;
            text-align: left;
            min-height: 192px;
          }

          //Attributes
          &-deal-name {
            color: $color-grey-darker;
            font-size: 16px;
            font-weight: bold;
            height: 35px;
            overflow: hidden;
            margin-bottom: 10px;
          }

          &-place-name {
            padding: 5px 0;
          }

          &-label-card {
            font-size: 14px;
            font-weight: bold;
            padding: 7px 25px;
          }

          &-attr {
            width: 50%;
            float: left;

            &-fs-bigger {
              font-size: 24px;
            }

            &-price {
              width: 50%;
              float: left;
              padding-left: 10px;
              text-align: right;
              margin-top: -12px;
              min-height: 68px;
            }
          }

          &-link-view {
            display: none;
          }
        }
      }
    }

    //General Style Elements for all col 1,2,3,4
    & .deal-item {
      margin-bottom: 20px;

      & .item {
        &-right {
          padding: 20px;
          background-color: $color-white;
          color: $color-grey-darker;
        }

        &-single-image {
          width: 100%;
          background-position: center center;
          background-repeat: no-repeat;
          height: 100%;
          position: relative;

          @include background-size(cover);
        }

        &-place-name {
          margin-bottom: 8px;
        }

        &-attr {
          margin-bottom: 5px;
        }

        &-label-card {
          position: absolute;
          top: 0;
          right: 20px;
          background: rgba(0, 98, 114, 0.9);
          z-index: 3;
          color: #FFF;
          text-transform: uppercase;
          max-width: 86%;
        }

        &-attr-price {
          color: $color-grey-darker;
        }

        &-attr-color-discount {
          color: #2ECC71;
        }

        &-attr-fs-bigger {
          position: relative;
          top: 3px;
        }

        &-link-view {
          width: 135px;
          margin: 20px auto 0 auto;

          &-inner {
            background-color: #6BA4B8;
            color: $color-white;
            padding: 10px;
            display: block;
            text-align: center;
            font-weight: bold;

            @include border-radius(4px);
          }
        }
      }
    }
  }
}

/* ************************************ */
