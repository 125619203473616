/** This Place For Reset **/
/* ************************************ */

html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation;

  > body > img {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
    z-index: -1;
  }
}
