@charset "utf-8";

/** This Place For Variable Style **/
@import 'variables.scss';
@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";

/* ************************************ */

/* ************************************ */

/** This Place For Mixins Style **/
@import 'mixins';
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-miscellaneous.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";

/* ************************************ */

/** This Place For Master App Style **/
@import 'core/master-apps.scss';

/* ************************************ */

/** TRAVLR Icons **/
@import '~@travlrcomlabs/icons/dist/css/travlr-icons.css';

.travlr-ui {
  .t-icon {
    font-family: "TRAVLR" !important;
  }
}

/** override 3rd party style **/
@import './libs/cookiebot.scss';
