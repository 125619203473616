/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Humm Offer **/
.#{$namespace} {
  & .c-humm-offer {
    background-color: #F3F4F6;
  }
}

/* ************************************ */
