/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

/******************************************
  Max Phone Screen
******************************************/
@media screen and (max-width: $screen-xs-max) {
  /******************************************
    Create an awesome styling here
  ******************************************/
  .#{$namespace} {
    & .c-humm-instruction-panel {
      padding: 32px 0 240px;

      & .floating-panel-wrapper {
        & .floating-panel-container {
          top: 32px;

          & .steps-container {
            display: block;

            & .step-col {
              display: block;
              width: 100%;
              height: 250px;

              & .step-img-container {
                height: 140px;
                width: 100%;
                display: table;

                & .inner-wrapper {
                  height: 150px;
                  width: 100%;
                  display: table-cell;
                  vertical-align: middle;

                  & .step-img-1 {
                    height: 90px;
                  }

                  & .step-img-2 {
                    height: 110px;
                  }

                  & .step-img-3 {
                    height: 115px;
                  }
                }
              }

              & .description-container {
                height: 60px;
                padding: 16px 24px 0;
              }
            }
          }
        }
      }
    }
  }
}
