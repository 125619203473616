/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Activity Hero Search **/
.#{$namespace} {
  & .c-flights-homepage-hero-search {
    @include mobile {
      & .#{$prefix} {
        &-hero-main {
          max-height: 100%;
          min-height: 100%;
          padding-bottom: 32px;
          padding-top: 32px;

          @include media-up(md) {
            padding-bottom: 64px;
            padding-top: 64px;
          }

          &-content {
            display: block;
            height: auto;
            min-height: auto;
            max-height: 100%;
          }

          &-background {
            max-height: 100%;
            min-height: 100%;
          }

          &-content-list {
            display: block;
          }

          &-content-list-title {
            display: none !important;
            height: auto;
            min-height: auto;

            &-content-list-title-wrapper {
              display: block;
            }
          }

          &-content-list-booking {
            background-color: transparent;
            padding-bottom: 0;
            padding-top: 0;

            .t-input-label {
              color: white !important;
            }
          }
        }
      }

      .flight-type-item-button {
        color: #FFF !important;
      }
    }
  }
}

/* ************************************ */
