/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Activity Hero Search **/
.#{$namespace} {
  & .c-activity-hero-search {
    & .#{$prefix} {
      &-hero-main {
        min-height: 440px;
        max-height: 440px;

        &-content {
          min-height: 440px;
          max-height: 440px;
        }
      }
    }
  }
}

/* ************************************ */
