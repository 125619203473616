/******************************************
  Humm FAQ
******************************************/

.#{$namespace} {
  & .c-humm-faq {
    & .humm-faq {
      &__container {
        padding-top: 64px !important;
        padding-bottom: 64px !important;

        & .t-accordion-item {
          border-radius: 4px;
          margin-top: 16px;

          & .t-accordion-title {
            padding: 16px 0;
            font-weight: normal;
          }

          & .list-bullet-solid {
            list-style: disc;
            margin-left: 16px;
          }
        }

        & .t-accordion-item-body {
          font-size: 14px;
        }

        & .active {
          & .color-humm {
            color: $color-humm;
          }

          & .t-accordion-item-body {
            border-top: 1px #D8D8D8 solid;
            padding: 16px;
          }
        }
      }
    }
  }
}

/* ************************************ */
