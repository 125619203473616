/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

/******************************************
  Max Phone Screen
******************************************/
@media screen and (max-width: $screen-xs-max) {
  .#{$namespace} {
    & .c-home-slider-tiles {
      & .c-carousel-card-slider-tiles {
        & .carousel-card-slider-tiles {
          &__container {
            &-content {
              & .content {
                &-main {
                  &.desktop {
                    display: none;
                  }

                  &.mobile {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }

      & .c-card {
        & .#{$prefix} {
          &-card-title {
            min-height: 80px;
            max-height: 80px;

            &-content {
              & .title {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}
