/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block staticpage-main **/
.#{$namespace} {
  & .c-staticpage-main {
    & .staticpage-wrapper {
      display: table;
      width: 100%;
      height: calc(100vh - 128px);
    }

    & .staticpage-content {
      display: table-row;
    }

    & .staticpage-footer-elements {
      display: table-cell;
      vertical-align: bottom;
    }
  }
}

/* ************************************ */
