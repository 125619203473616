.c-bnpl-banner-hero {
  padding: 48px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .radial-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle at 25% 20%, #F1F2EE, rgba(241, 242, 238, 0));
    top: 0;
    left: 0;
  }

  .bnpl-banner-hero {
    &__container {
      .inner-container {
        height: 100%;
        z-index: 2;

        .left-container {
          .bnpl-horizontal-img {
            width: 400px;
            max-width: 100%;
          }
        }
      }
    }
  }

  .bnpl-hero-term-text {
    font-style: italic;
    opacity: 0.8;
  }

  @media screen and (max-width: 767px) {
    .left-container {
      text-align: center;
      width: 100%;
      padding-right: 0;

      .bnpl-horizontal-img {
        width: 220px !important;
      }

      .bnpl-title {
        font-size: 22px;
      }

      .t-label-text {
        font-size: 16px;
        padding: 0 42px;
      }
    }

    .right-container {
      display: none !important;
    }

    .bnpl-hero-desc {
      max-width: 100%;
    }
  }
}

.c-bnpl-instruction-panel {
  padding: 64px 0 128px;
  z-index: 3;

  .floating-panel-wrapper {
    .floating-panel-container {
      margin: 0 auto;
      border-radius: 8px;
      top: 64px;
      width: 100%;

      @include box-shadow(0 4px 24px rgba(0, 0, 0, 0.08));

      @media screen and (max-width: 767px) {
        padding: 16px !important;
      }

      .steps-container {
        .step-col {
          .step-img-container {
            width: 100%;

            .inner-wrapper {
              width: 100%;

              .step-img-1,
              .step-img-2,
              .step-img-3 {
                height: 160px;
              }
            }
          }
        }
      }
    }
  }
}

.c-bnpl-plan-table {
  background-color: #F3F4F6;
  padding: 48px 0;
  padding-top: 300px;
  padding-bottom: 0;

  @media screen and (max-width: 1024px) {
    padding-top: 750px;
  }

  .bnpl-plan-table {
    &__container {
      .big-buy-btn {
        display: none;
      }

      .buy-btn {
        display: none;
        font-size: 14px;
        border: 1px #FE6B00 solid;
        border-radius: 4px;
        padding: 8px 0;
        width: 240px;
        cursor: pointer;

        .btn-icon {
          width: 26px;
        }

        .btn-text {
          display: inline-block;

          @include transform(translateY(-2px));
        }
      }

      .table-container {
        font-size: 16px;

        &.bordered-table {
          border: 1px solid #D1D5DB;
          border-radius: 8px;
          padding: 24px 48px 48px;

          @media screen and (max-width: 767px) {
            padding: 40px 24px;
          }
        }

        .table-inner-container {
          .table-cell {
            .table-cell-content {
              border-bottom: 1px #D1D5DB solid;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 16px 0;
              line-height: 24px;

              @media screen and (max-width: 767px) {
                &:first-child {
                  padding-top: 0;
                }
              }
            }

            .buy-logo {
              width: 70px;
            }

            .fs-28 {
              font-size: 28px;
            }
          }
        }
      }
    }
  }

  .t-w-25 {
    width: 25%;
  }

  .fw-bold {
    font-weight: bold;
  }
}

.c-bnpl-questions {
  background-color: #F3F4F6;

  .checklist-wrapper {
    line-height: 24px;

    li {
      display: list-item;
    }
  }
}

.c-bnpl-signup {
  .bnpl-signup {
    &__container {
      .bnpl-signup-btn {
        color: #FF7412;
        background-color: transparent !important;
        border: 1px #FF7412 solid;
      }
    }
  }
}

.c-bnpl-offer {
  background-color: #F3F4F6;
}

.c-bnpl-terms {
  a {
    text-decoration: underline;
  }
}
