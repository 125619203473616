/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

@media screen and (max-width: 768px) {
  .#{$namespace} {
    & .c-banner-app {
      & .bg-banner {
        height: auto;
        padding-bottom: 32px;

        & .inner-container {
          & .content-container {
            right: -22px;
            padding-left: 0;

            & img {
              width: 100px;
            }

            & h2 {
              font-size: 24px;
            }

            & p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

/******************************************
  Max Phone Screen
******************************************/
@media screen and (max-width: $screen-xs-max) {
  .#{$namespace} {
    & .c-banner-app {
      & .bg-banner {
        & .inner-container {
          & .content-container {
            right: -33px;

            & img {
              width: 100px;
            }

            & h2 {
              font-size: 24px;
            }

            & p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .#{$namespace} {
    & .c-banner-app {
      & .bg-banner {
        & .inner-container {
          & .content-container {
            & .download-wrapper {
              & a {
                padding-left: 0 !important;
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
