/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

@media screen and (max-width: $screen-md-max) {
  .#{$namespace} {
    & .c-home-find-inspiration-personalized {
      padding-top: 16px;

      & .home-find-inspiration-personalized {
        &__panel {
          & .link {
            &:hover {
              background: transparent !important;
              border-color: unset;
            }

            &.mobile {
              display: inline-block;
            }
          }

          & .column-item {
            &.last-item {
              display: none;
            }
          }
        }
      }
    }
  }
}

/******************************************
  Max Phone Screen
******************************************/
@media screen and (max-width: $screen-xs-max) {
  .#{$namespace} {
    & .c-home-find-inspiration-personalized {
      & .home-find-inspiration-personalized {
        &__container {
          & .c-card {
            & .#{$prefix} {
              &-card-title {
                min-height: 150px;
                max-height: 150px;

                &-content {
                  &.bottom {
                    top: 65%;
                  }

                  & .title {
                    font-size: 20px;

                    & .t-icon {
                      font-size: 20px;
                    }
                  }

                  & .description {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }

        &__panel {
          & .link {
            &:hover {
              background: transparent !important;
              border-color: unset;
            }
          }
        }
      }
    }
  }
}
