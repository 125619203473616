/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Activity Hero Search **/
.#{$namespace} {
  & .activity-detail-headline-price {
    background-color: #F1FCFD;
    border-radius: 4px;
    padding: 16px;
    border: 1px solid $color-secondary;
    position: relative !important;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;

    &.hide {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    & .headline-price-label {
      font-size: 14px;
      font-weight: 300;
    }
  }

  & .intercom-launcher-frame {
    bottom: 80px;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
  }
}

/* ************************************ */
