/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Example **/
.#{$namespace} {
  & .c-map-thumb-link {
    background: rgba(255, 255, 255, 0.8);
    bottom: 10px;
    left: 10px;
    padding: 4px 8px;
    text-align: center;
    z-index: 1000;

    @include border-radius(4px);

    &:hover {
      cursor: pointer;
    }
  }

  & .c-booking-link {
    width: 200px;
    max-width: 100%;
    border: 1px solid $color-secondary;
    color: $color-secondary;
    padding: 10px 15px;
    border-radius: 4px;
    font-weight: 700;
    display: inline-block;
    font-size: 16px;
    background-color: transparent;

    @include box-shadow(inset 0 0 0 0 rgba(0,0,0,0.25));
  }
}

/* ************************************ */
