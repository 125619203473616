/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

@media screen and (max-width: 768px) {
  /******************************************
    Create an awesome styling here
  ******************************************/
  .#{$namespace} {
    & .c-humm-plan-table {
      & .fs-24-tablet {
        font-size: 24px !important;
      }
    }
  }
}

/******************************************
  Max Phone Screen
******************************************/
@media screen and (max-width: $screen-xs-max) {
  /******************************************
    Create an awesome styling here
  ******************************************/
  .#{$namespace} {
    & .c-humm-plan-table {
      padding-top: 600px;

      & .humm-plan-table {
        &__container {
          & .table-container {
            font-size: 14px;

            & .table-inner-container {
              & .table-cell {
                & .table-cell-content {
                  padding: 0;
                  height: 60px;

                  &.top-row {
                    padding: 0 !important;
                    height: 0 !important;

                    & .top-row-content {
                      display: none;
                    }
                  }
                }

                & .fs-28 {
                  font-size: 20px;
                }
              }

              & .row-title {
                width: 43%;
              }

              & .big-buy {
                width: 57%;
                vertical-align: bottom;
              }

              & .small-buy {
                width: 57%;
                vertical-align: bottom;
              }
            }
          }

          &.small-buy-mobile {
            & .buy-btn-container {
              height: 37px;

              & .small-buy-btn {
                display: block;
                position: absolute;
                left: 50%;

                @include transform(translateX(-50%));
              }

              & .big-buy-btn {
                border: 1px #CCC solid !important;
                color: #384044;
                opacity: 0.5;
                display: block;
                position: absolute;
                left: 50%;

                @include transform(translateX(-50%));
              }
            }

            & .table-container {
              & .table-inner-container {
                & .big-buy {
                  display: none;
                  pointer-events: auto;
                }
              }
            }
          }

          &.big-buy-mobile {
            & .buy-btn-container {
              height: 37px;

              & .big-buy-btn {
                display: block;
                position: absolute;
                left: 50%;

                @include transform(translateX(-50%));
              }

              & .small-buy-btn {
                border: 1px #CCC solid !important;
                color: #384044;
                opacity: 0.5;
                display: block;
                position: absolute;
                left: 50%;

                @include transform(translateX(-50%));
              }
            }

            & .table-container {
              & .table-inner-container {
                & .small-buy {
                  display: none;
                }
              }
            }
          }

          & .t-heading-2 {
            font-size: 20px;
          }

          & .t-label-text {
            font-size: 14px;
          }
        }
      }
    }
  }
}
