/******************************************
  Flights
******************************************/

@import "./flights-homepage/index";
@import "./flights-search-page/index";

// PointHack WLP

.c-pointhack-point-banner {
  .point-btn {
    line-height: 20px;
    padding: 14px;
  }
}
