.quote-status {
  &-booked {
    background-color: $color-green-50;
    color: $color-green;
  }

  &-pending {
    background-color: $color-yellow-50;
    color: $color-warning-v2;
  }

  &-draft {
    background-color: $color-grey-100;
    color: $color-grey-500;
  }
}
