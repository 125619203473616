/******************************************
  Humm
******************************************/

@import "./block-humm-instruction-panel";
@import "./block-humm-plan-table";
@import "./block-humm-questions";
@import "./block-humm-signup";
@import "./block-humm-offer";

.color-humm {
  color: $color-humm;
}

.checklist-wrapper {
  .icon-check {
    font-size: 20px;
    color: #1F2937;
  }
}
