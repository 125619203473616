/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

/******************************************
  Max Phone Screen
******************************************/
@media screen and (max-width: $screen-xs-max) {
  /******************************************
    Create an awesome styling here
  ******************************************/
  .#{$namespace} {
    & .c-humm-offer {
      & .t-heading-2 {
        font-size: 20px;
      }

      & .t-label-text {
        font-size: 14px;
      }

      &.t-p-t-64 {
        padding-top: 32px;
      }

      &.t-p-b-64 {
        padding-bottom: 32px;
      }
    }
  }
}
