/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

@media screen and (min-width: 620px) {
  /******************************************
    Create an awesome styling here
  ******************************************/
  .#{$namespace} {
    & .c-home-deals {
      & .home-deals {
        &__container {
          & .carousel-item {
            width: 45%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  /******************************************
    Create an awesome styling here
  ******************************************/
  .#{$namespace} {
    & .c-home-deals {
      & .hide-desktop {
        display: none;
      }

      & .home-deals {
        &__special-deals {
          display: block;
        }

        &__container {
          & .carousel-item {
            width: 50%;

            &.first-item {
              padding-left: 8px;
            }
          }
        }
      }
    }
  }
}

/******************************************
  Medium Screen
******************************************/
@media screen and (min-width: $screen-medium) {
  /******************************************
    Create an awesome styling here
  ******************************************/
  .#{$namespace} {
    & .c-home-deals {
      & .home-deals {
        &__container {
          & .carousel-item {
            width: 33.33333%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  /******************************************
    Create an awesome styling here
  ******************************************/
  & .c-home-deals {
    & .home-deals__container {
      overflow-x: hidden;
    }

    & .hide-mobile {
      display: none;
    }
  }
}

/******************************************
  Max Phone Screen
******************************************/
@media screen and (max-width: $screen-sm-max) {
  .#{$namespace} {
    & .c-home-deals {
      & .home-deals {
        &__container {
          & .c-dow {
            height: 250px;

            & .dow-inner {
              & .dow-right {
                padding: 16px;

                h2 {
                  max-height: 60px;
                  overflow: hidden;
                }

                & .t-heading-2 {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
