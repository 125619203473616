/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

@media screen and (max-width: $screen-md-max) {
  .#{$namespace} {
    & .c-card {
      &.card {
        &-no-background {
          & .t-card-content-footer {
            min-height: 68px;
          }
        }
      }
    }
  }
}

/******************************************
  Max Phone Screen
******************************************/
@media screen and (max-width: $screen-xs-max) {
  .#{$namespace} {
    & .c-card {
      &.card {
        &-no-background {
          min-height: 200px;
          max-height: 200px;

          & .t-card-content-header {
            min-height: 125px;
            max-height: 125px;
          }
        }
      }
    }
  }
}
