/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

/******************************************
  Max Phone Screen
******************************************/
@media screen and (max-width: $screen-xs-max) {
  .#{$namespace} {
    & .c-activity-hero-search {
      & .#{$prefix} {
        &-hero-main {
          min-height: unset;
          max-height: unset;

          &-content {
            min-height: unset;
            max-height: unset;
          }
        }
      }
    }
  }
}
