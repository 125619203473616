/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Find Inspiration **/
.#{$namespace} {
  & .c-humm-signup {
    & .humm-signup {
      &__container {
        & .humm-signup-btn {
          color: #FF7412;
          background-color: transparent !important;
          border: 1px #FF7412 solid;
        }
      }
    }
  }
}

/* ************************************ */
