/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Example **/
.#{$namespace} {
  & .c-listing-tips {
    & .tips {
      &-wrapper {
        background: rgba(45, 204, 211, 0.05);
        color: #508893;
        padding: 10px 20px 16px 20px;
        font-weight: 500;
      }

      &-item {
        list-style: none;

        &::before {
          display: none;
        }

        &-icon {
          width: 25px;
        }

        &-text {
          font-weight: 500;
          font-size: 16px;
          color: $color-tertiary;
          line-height: 1.4;
        }
      }
    }

    & .t-icon {
      padding-right: 10px;
      font-size: 20px;
      top: 2px;
      position: relative;
      color: $color-tertiary;
    }
  }
}

/* ************************************ */
