/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Banner App **/
.#{$namespace} {
  & .c-banner-app {
    margin-top: 64px;
    background: rgba(55, 64, 68, 0.4);

    + section {
      margin-top: 0 !important;
    }

    & .bg-banner {
      width: 100%;
      height: 550px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      & .inner-container {
        position: relative;

        & .content-container {
          color: white;
          padding-top: 80px;
          padding-right: 40%;
          padding-left: 10%;

          & .app-logo-link {
            img {
              height: auto;
              left: -1px;
              margin-bottom: 0;
              position: relative;
              top: -1px;
            }
          }

          & img {
            width: 144px;
          }

          & h2 {
            font-size: 40px;
            margin-bottom: 14px;
          }

          & p {
            font-size: 20px;
            max-width: 380px;
            margin-bottom: 20px;
          }

          & .download-wrapper {
            display: table;

            & a {
              &.pl-10 {
                padding-left: 10px;
              }
            }

            img {
              height: auto;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}

/* ************************************ */
