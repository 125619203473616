/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Page Empty **/
.#{$namespace} {
  & .c-page-empty {
    font-size: 24px;
    position: relative;
    text-align: center;
    height: calc(100vh - 128px);

    & .page-empty {
      &-wording {
        color: #B9B8B8;
        width: 100%;
        height: 100%;
        padding: 48px;
        display: table;
        text-align: center;

        & .wording {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
  }
}

/* ************************************ */
