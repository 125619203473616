.#{$namespace} {
  // NOTE: override UIkit
  .t-form {
    .t-input-text-tinted,
    .t-input-textarea-tinted,
    .t-input-select-tinted {
      &.t-background-white {
        background-color: $color-white;
      }
    }

    .has-error {
      .t-input-text-tinted,
      .t-input-textarea-tinted,
      .t-input-select-tinted {
        border-color: $color-danger;
        border-width: 2px;

        &:focus,
        &:hover {
          border-color: $color-danger !important;
          border-width: 2px;
        }
      }
    }
  }
}
